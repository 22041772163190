import { Alert, Button } from '@material-tailwind/react';
import { Form, useLoaderData, useSearchParams } from '@remix-run/react';
import { mapErrorsToCaption } from '~/constants/errors';
import { BACKEND_URI } from '~/env.ts';
import logo from '~/images/logo.png';

export const loader = async () => {
  return {
    BACKEND_URI,
  };
};

export default function Login() {
  const { BACKEND_URI } = useLoaderData<typeof loader>();
  const [searchParams] = useSearchParams();

  const error = searchParams.get('error');
  const handleLogin = async () => {
    /**
     * We have to do the redirect on frontend in order for the cookie to be set.
     * Looks like the cookies gets ignored if we do a backend redirect
     */
    const res = await fetch(`${BACKEND_URI}/authentication/authUrl`, {
      method: 'GET',
      credentials: 'include',
    });

    location.href = await res.text();
  };

  return (
    <div className="flex flex-col justify-center items-center w-full h-full bg-sp-gras-50/50">
      <div className="flex flex-col items-center bg-white rounded-3xl max-w-144 p-10">
        {error && (
          <Alert color={'red'} variant={'outlined'}>
            {mapErrorsToCaption(error)}
          </Alert>
        )}
        <div className="mx-auto p-8">
          <img src={logo} alt="Senterpartiet logo" className="mx-auto w-20" />
        </div>
        <h1 className="text-sp-skog text-4xl font-extrabold font-serif">
          Velkommen til Spir
        </h1>
        <Form method="post">
          <Button
            className="m-10 bg-sp-clover-900 capitalize rounded-3xl"
            type="button"
            onClick={handleLogin}
          >
            Logg inn
          </Button>
        </Form>
        <div className="bg-sp-gras-50 flex flex-col items-center p-8 mx-20 gap-4">
          <h3 className="text-xl font-bold text-gray-900">Bli medlem</h3>
          <p>
            Registrer deg på
            <a
              href="https://minside.sp.no/Membership/BuyMembership"
              target="_blank"
              rel="noopener noreferrer"
              className=" underline mx-1 underline-offset-4"
            >
              Min Side
            </a>
            (her kan du betale med Vipps og kredittkort)
          </p>

          <a
            href="https://www.senterpartiet.no/bli-medlem"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              className=" border-sp-clover-600 text-gray-900 rounded-3xl capitalize"
              variant="outlined"
            >
              Bli medlem
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
