import {
  REFRESH_TOKEN_NOT_FOUND,
  NO_REFRESH_TOKEN,
  EXPIRED_REFRESH_TOKEN,
  NOT_ACCESS_TOKEN,
  NOT_REFRESH_TOKEN,
  INVALID_REFRESH_TOKEN,
} from '@fink/exceptions/index.ts';

export const mapErrorsToCaption = (error: string) => {
  switch (error) {
    case NO_REFRESH_TOKEN:
      return 'Ingen refresh token, vennligst prøv å logg inn på nytt';
    case REFRESH_TOKEN_NOT_FOUND:
      return 'Refresh token ikke funnet i systemet, vennligst prøv å logg inn på nytt';
    case INVALID_REFRESH_TOKEN:
      return 'Ugyldig refresh token, vennligst prøv å logg inn på nytt';
    case EXPIRED_REFRESH_TOKEN:
      return 'Utgått refresh token, vennligst prøv å logg inn på nytt';
    case NOT_ACCESS_TOKEN:
      return 'Ugyldig access token, vennligst prøv å logg inn på nytt';
    case NOT_REFRESH_TOKEN:
      return 'Ugyldig refresh token, vennligst prøv å logg inn på nytt';
    default:
      return error;
  }
};
